import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Open WOD 20.4`}</strong></p>
    <p>{`For time:\\
30 box jumps, 24/20 in\\
15 clean and jerks, 95/65 lb.\\
30 box jumps\\
15 clean and jerks, 135/85 lb.\\
30 box jumps\\
10 clean and jerks, 185/115 lb.\\
30 single-leg squats\\
10 clean and jerks, 225/145 lb.\\
30 single-leg squats\\
5 clean and jerks, 275/175 lb.\\
30 single-leg squats\\
5 clean and jerks, 315/205 lb.`}</p>
    <p>{`Time cap: 20 minutes`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Granite Games Winter Throwdown is December 7th here at The
Ville!  Another opportunity to earn a spot at the Granite Games
Championships next year.  The last day to register your team and get
your shirt on the day of the competition is November 13th so don’t wait,
sign up today!  We’re only taking 30 teams and we’ve sold out for both
Throwdowns so get your team registered!  `}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`*`}{`only 4 spots remaining!`}{`*`}{`*`}{`*`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://thegranitegames.com/about-throwdowns/#locations"
      }}>{`https://thegranitegames.com/about-throwdowns/#locations`}</a></p>
    <p><strong parentName="p">{`*`}{`*`}{`TODAY is the last day to sign up as a volunteer and get your
shirt!`}{`*`}{`  **`}</strong>{`We are in need of Judges for our Granite Games Winter
Throwdown on December 7th.  If you aren’t competing please consider
helping out!  We’ll get you a shirt (if you volunteer by Nov 3rd), and
feed you throughout the day.  Sign up on competition corner.net and be
sure to put in your shirt size.***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      